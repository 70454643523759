body {
  background-image: url('./Assets/starry_sky_galaxy_stars_125852_1920x1080.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  /* background-color: #282c34; */
  /* color: white; */
  min-height: 100vh;
  /* text-align: center; */ 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
